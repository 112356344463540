import Navbar from "../components/Navbar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import { useNavigate } from "react-router-dom";

function Dashboard() {
  const navigate = useNavigate();
  return (
    <div>
      <Navbar heading="Dashboard" />
      <Box sx={{ width: "37%", marginTop: "7%", marginLeft: "30%" }}>
        <Stack spacing={2}>
          <Button
            onClick={() => {
              navigate("/notice");
            }}
            variant="outlined"
          >
            NOTICE BOARD
          </Button>
          <Button
            onClick={() => {
              navigate("/announcement");
            }}
            variant="outlined"
          >
            ANNOUNCEMENT
          </Button>
          <Button
            onClick={() => {
              alert("This feature is coming soon.");
            }}
            variant="outlined"
          >
            BIRTHDAYS
          </Button>
          <Button
            onClick={() => {
              alert("This feature is coming soon.");
            }}
            variant="outlined"
          >
            GALLERY
          </Button>
        </Stack>
      </Box>
    </div>
  );
}

export default Dashboard;

import { auth } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate } from "react-router-dom";

export function PrivateRoute({ children, redirectTo }) {
  const [user, loading, error] = useAuthState(auth);
  if (error) {
    console.log(error);
  }
  if (loading) {
    // maybe trigger a loading screen
    return;
  }
  if (user) {
    return children;
  }
  return <Navigate to={redirectTo} />;
}

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Reset from "./pages/Reset";
import Dashboard from "./pages/Dashboard";
import Notice from "./pages/Notice";
import Announcement from "./pages/Announcement";
import { PrivateRoute } from "./PrivateRoutes";

function App() {
  const defaultTheme = createTheme();
  return (
    <div>
      <ThemeProvider theme={defaultTheme}>
        <Router>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/reset" element={<Reset />} />
            <Route
              exact
              path="/dashboard"
              element={
                <PrivateRoute redirectTo="/">
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/notice"
              element={
                <PrivateRoute redirectTo="/">
                  <Notice />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/announcement"
              element={
                <PrivateRoute redirectTo="/">
                  <Announcement />
                </PrivateRoute>
              }
            />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;

import Navbar from "../components/Navbar";
import Bulletin from "../components/Bulletin";

function Announcement() {
  return (
    <div>
      <Navbar heading="Announcement" />
      <Bulletin />
    </div>
  );
}

export default Announcement;

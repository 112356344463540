import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import {
  getDoc,
  setDoc,
  doc,
  updateDoc,
  deleteField,
} from "firebase/firestore";
import { db, storage } from "../firebase";

import Button from "@mui/material/Button";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";

import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

function createData(id, date, title, link, time, location) {
  return { id, date, title, link, time, location };
}

export default function Board() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);

  let selectRows = new Map();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheck = (event, id, pth) => {
    if (event.target.checked) {
      selectRows.set(id, pth);
    } else {
      selectRows.delete(id);
    }
  };
  const handleDelete = async () => {
    if (selectRows.size < 1) {
      alert("Select notice to delete.");
      return;
    }
    setLoading(true);
    try {
      for (const [key, value] of selectRows) {
        const fileRef = ref(storage, value);
        const docRef = doc(db, "notice", "board");
        await updateDoc(docRef, {
          [`obj.${key}`]: deleteField(),
        });
        // await deleteDoc(doc(db, "notice", key));

        await deleteObject(fileRef);
      }
    } catch (error) {
      alert("File delete failed");
      console.log(error);
    } finally {
      loadRows();
    }
    setLoading(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const title = data.get("title");
    const file = data.get("file");

    if (!title.trim()) {
      alert("Please enter a title.");
      setLoading(false);
      return;
    }

    if (file.name === "") {
      alert("Please upload a PDF file.");
      setLoading(false);
      return;
    }

    const fileName = file.name;
    const fileExtension = fileName.split(".").pop().toLowerCase();

    if (fileExtension !== "pdf") {
      alert("Please upload a PDF file.");
      setLoading(false);
      return;
    }

    const pth = "notice/" + Date.now().toString() + ".pdf";
    const noticeRef = ref(storage, pth);
    const metadata = {
      contentType: "application/pdf",
    };

    try {
      const snapshot = await uploadBytes(noticeRef, file, metadata);
      const downloadURL = await getDownloadURL(snapshot.ref);
      const boardRef = doc(db, "notice", "board");
      const fieldName = Date.now().toString();
      const obj = {
        [fieldName]: [new Date(), title, pth, downloadURL],
      };
      await setDoc(boardRef, { obj }, { merge: true });
    } catch (error) {
      alert("File upload failed");
      console.log(error);
    } finally {
      handleClose();
      loadRows();
    }
    setLoading(false);
  };

  const loadRows = async () => {
    console.log("made request");
    setLoading(true);
    const docRef = doc(db, "notice", "board");
    try {
      const docSnap = await getDoc(docRef);
      const tempRows = [];
      if (docSnap.exists()) {
        const data = docSnap.data()["obj"];
        for (let [key, value] of Object.entries(data)) {
          var utc = value[0].toDate();
          tempRows.push(
            createData(
              key,
              utc.toLocaleDateString(),
              value[1],
              value[3],
              utc,
              value[2]
            )
          );
        }
        tempRows.sort((a, b) => {
          return b.time - a.time;
        });
        setRows(tempRows);
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    } catch (error) {
      alert("Data could not be loaded.");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadRows();
  }, []);

  return (
    <div>
      <Box textAlign="center" sx={{ marginTop: "2%" }}>
        <Button
          variant="outlined"
          startIcon={<AddCircleRoundedIcon />}
          onClick={handleClickOpen}
        >
          ADD NOTICE
        </Button>
        <Button
          variant="outlined"
          startIcon={<DeleteIcon />}
          sx={{ ml: "10px" }}
          onClick={handleDelete}
        >
          DELETE NOTICE
        </Button>

        <Dialog open={open} onClose={handleClose}>
          <Box component="form" onSubmit={handleSubmit}>
            <DialogTitle>Upload</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please ensure to carefully review your notice before uploading.
                Title can be maximum 100 characters.
              </DialogContentText>
              <TextField
                autoFocus
                name="title"
                margin="dense"
                id="title"
                label="Title"
                type="text"
                fullWidth
                variant="standard"
                inputProps={{ maxLength: 100 }}
                sx={{ mb: "20px" }}
              />
              <TextField
                autoFocus
                name="file"
                margin="dense"
                id="file"
                type="file"
                fullWidth
                variant="standard"
                inputProps={{ accept: ".pdf" }}
                sx={{ mb: "20px" }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" onClick={handleClose}>
                Submit
              </Button>
            </DialogActions>
          </Box>
        </Dialog>

        {loading ? (
          <Box sx={{ mt: "40px" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ overflowX: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Select</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell align="right">View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Checkbox
                        key={new Date().getSeconds()}
                        onChange={(event) =>
                          handleCheck(event, row.id, row.location)
                        }
                      />
                    </TableCell>
                    <TableCell>{row.date}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell align="right">
                      <a
                        href={row.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Link
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        )}
      </Box>
    </div>
  );
}

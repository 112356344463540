import Navbar from "../components/Navbar";
import Board from "../components/Board";

import React from "react";

function Notice() {
  return (
    <div>
      <Navbar heading="Notice" />
      <Board />
    </div>
  );
}

export default Notice;

import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import {
  getDoc,
  setDoc,
  doc,
  updateDoc,
  deleteField,
} from "firebase/firestore";
import { db } from "../firebase";

import Button from "@mui/material/Button";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";

function createData(id, date, subject, time) {
  return { id, date, subject, time };
}

export default function Bulletin() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);

  let selectRows = new Set();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheck = (event, id) => {
    if (event.target.checked) {
      selectRows.add(id);
    } else {
      selectRows.delete(id);
    }
  };
  const handleDelete = async () => {
    if (selectRows.size < 1) {
      alert("Select announcement to delete.");
      return;
    }
    setLoading(true);
    try {
      for (const key of selectRows.values()) {
        const docRef = doc(db, "announcement", "bulletin");
        await updateDoc(docRef, {
          [`obj.${key}`]: deleteField(),
        });
      }
    } catch (error) {
      alert("Announcement delete failed");
      console.log(error);
    } finally {
      loadRows();
    }
    setLoading(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const subject = data.get("subject");

    if (!subject.trim()) {
      alert("Please enter a subject.");
      return;
    }

    try {
      const boardRef = doc(db, "announcement", "bulletin");
      const fieldName = Date.now().toString();
      const obj = {
        [fieldName]: [new Date(), subject],
      };
      await setDoc(boardRef, { obj }, { merge: true });
    } catch (error) {
      alert("Add announcement failed");
      console.log(error);
    } finally {
      handleClose();
      loadRows();
    }
    setLoading(false);
  };

  const loadRows = async () => {
    console.log("made request");
    setLoading(true);
    const docRef = doc(db, "announcement", "bulletin");
    try {
      const docSnap = await getDoc(docRef);
      const tempRows = [];
      if (docSnap.exists()) {
        const data = docSnap.data()["obj"];
        for (let [key, value] of Object.entries(data)) {
          var utc = value[0].toDate();
          tempRows.push(
            createData(key, utc.toLocaleDateString(), value[1], utc)
          );
        }
        tempRows.sort((a, b) => {
          return b.time - a.time;
        });
        setRows(tempRows);
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    } catch (error) {
      alert("Data could not be loaded.");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadRows();
  }, []);

  return (
    <div>
      <Box textAlign="center" sx={{ marginTop: "2%" }}>
        <Button
          variant="outlined"
          startIcon={<AddCircleRoundedIcon />}
          onClick={handleClickOpen}
        >
          ADD
        </Button>
        <Button
          variant="outlined"
          startIcon={<DeleteIcon />}
          sx={{ ml: "10px" }}
          onClick={handleDelete}
        >
          DELETE
        </Button>

        <Dialog open={open} onClose={handleClose}>
          <Box component="form" onSubmit={handleSubmit}>
            <DialogTitle>Upload</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please ensure to carefully review the announcement before
                uploading. Subject can be maximum 350 characters.
              </DialogContentText>
              <TextField
                autoFocus
                name="subject"
                margin="dense"
                id="subject"
                label="subject"
                type="text"
                fullWidth
                variant="standard"
                inputProps={{ maxLength: 350 }}
                sx={{ mb: "20px" }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" onClick={handleClose}>
                Submit
              </Button>
            </DialogActions>
          </Box>
        </Dialog>

        {loading ? (
          <Box sx={{ mt: "40px" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ overflowX: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Select</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Subject</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Checkbox
                        key={new Date().getSeconds()}
                        onChange={(event) => handleCheck(event, row.id)}
                      />
                    </TableCell>
                    <TableCell>{row.date}</TableCell>
                    <TableCell align="justify">{row.subject}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        )}
      </Box>
    </div>
  );
}
